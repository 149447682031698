import { render, staticRenderFns } from "./OPDPackageListAppointments.vue?vue&type=template&id=52157f6c&scoped=true&"
import script from "./OPDPackageListAppointments.vue?vue&type=script&lang=js&"
export * from "./OPDPackageListAppointments.vue?vue&type=script&lang=js&"
import style0 from "./OPDPackageListAppointments.vue?vue&type=style&index=0&id=52157f6c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52157f6c",
  null
  
)

export default component.exports